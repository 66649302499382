import "./Content.css";

import { useTranslation } from "react-i18next";

import { Section } from "./Section";

export function Content() {
    const { t } = useTranslation();

    return (
        <div
            id="content"
        >
            <h1 id="company">{t("company")}</h1>

            <Section />
        </div>
    )
}
