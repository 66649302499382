import "./LanguageSelector.css";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { LANGUAGES, Language } from "../i18n";

export function LanguageSelector() {
    return (
        <div
            id="language--selector"
        >
            {
                LANGUAGES.map((language: Language): JSX.Element => (
                    <LanguageItem
                        key={language.id}
                        language={language}

                    />
                ))
            }
        </div>
    );
}

function LanguageItem(props: {
    readonly language: Language;
}) {
    const { t } = useTranslation();

    const isSelected: boolean = i18n.language === props.language.id;

    return (
        <div
            className={
                isSelected
                    ? "language--item--container--selected"
                    : "language--item--container--notselected"
            }
            onClick={(): void => {
                i18n.changeLanguage(props.language.id);
            }}
        >
            <p>
                {t(props.language.i18nKey)}
            </p>

        </div>
    );
}
