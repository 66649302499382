import "./Footer.css";
import { Trans, useTranslation } from "react-i18next";

import { SocialNetwork } from "../components/SocialNetwork";
import { Github, Linkedin, Youtube } from "../assets/icons";
import { LINK_GITHUB, LINK_LINKEDIN, LINK_YOUTUBE } from "../config/constantes";

const ICON_WIDTH = 50 as const;

export function Footer() {
    const { t } = useTranslation();

    return (
        <footer
            id="footer"
        >
            <div
                id="contact"
            >
                {/* social media */}
                <div
                    id="social-media"
                >
                    <SocialNetwork
                        link={LINK_GITHUB}
                    >
                        <Github size={{ width: ICON_WIDTH }} />
                    </SocialNetwork>

                    <SocialNetwork
                        link={LINK_YOUTUBE}
                    >
                        <Youtube size={{ width: ICON_WIDTH }} />
                    </SocialNetwork>

                    <SocialNetwork
                        link={LINK_LINKEDIN}
                    >
                        <Linkedin size={{ width: ICON_WIDTH }} />
                    </SocialNetwork>
                </div>

                <p id="location">{t("footer_location")}</p>
            </div>

            <Trans
                i18nKey={"footer_copyright"}
            >
                <p
                    id="copyright--content"
                >
                    <span id="copyright--company">Company</span>
                    end copyright
                </p>
            </Trans>

        </footer>
    );
}
