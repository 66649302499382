export const ENGLISH = "en" as const;
export const FRENCH = "fr" as const;

export type LanguageId =
    | typeof ENGLISH
    | typeof FRENCH;

export type Language = {
    readonly id: LanguageId;
    readonly i18nKey: string;
};

export const LANGUAGES: Array<Language> = [
    {
        id: ENGLISH,
        i18nKey: "language_english",
    },
    {
        id: FRENCH,
        i18nKey: "language_french",
    },
];
