import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as LOCALE_i18n from "../i18n";

type LoadingFunction = () => Promise<boolean>;

const loadI18n: LoadingFunction = async (): Promise<boolean> => {
    await i18n
        .use(initReactI18next)
        .init({
            lng: LOCALE_i18n.ENGLISH, // default is english
            resources: {
                [LOCALE_i18n.ENGLISH]: {
                    translation: LOCALE_i18n.ENGLISH_TRAD,
                },
                [LOCALE_i18n.FRENCH]: {
                    translation: LOCALE_i18n.FRENCH_TRAD,
                },
            }
        });

    return (true);
};

/**
 * Contains array of all functions that need to be loaded
 */
const LOADING_FUNCTIONS: Array<LoadingFunction> = [
    loadI18n,
];

/**
 * Load all necessary elements for the website
 */
export const load = async (callback: () => void): Promise<void> => {
    await Promise.all(
        LOADING_FUNCTIONS.map(
            (f: LoadingFunction): Promise<boolean> => {
                return (f());
            }
        )
    );

    // trigger callback
    callback();
};
