import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// import fonts
import "./assets/fonts/calling_code/CallingCode-Regular.ttf";
import "./assets/fonts/mono/Mono\ Regular.ttf";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
