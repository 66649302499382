import { IconSize } from "./interfaces";

/**
 * From the W/H ratio compute size of the icon
 * @param iconSize
 * @param ratio
 */
export const getIconSize = (iconSize: IconSize, ratio: number): {
    readonly height: number;
    readonly width: number;
} => {
    if ("width" in iconSize) {
        return ({
            height: iconSize.width * ratio,
            width: iconSize.width,
        });
    }

    return ({
        height: iconSize.height,
        width: iconSize.height / ratio,
    });
};
