import "./Section.css";

import { useTranslation } from "react-i18next"

export function Section() {
    const { t } = useTranslation();

    return (
        <div
            id="section"
        >
            <CommentLine
                binaryCode="00"
            >{t("section_development")}</CommentLine>
            <CommentLine
                binaryCode="01"
            >{t("section_saas")}</CommentLine>
            <CommentLine
                binaryCode="10"
            >{t("section_cross-platform")}</CommentLine>
            <CommentLine
                binaryCode="11"
            >{t("section_cloud-architect")}</CommentLine>
        </div>
    )
}

function CommentLine(props: {
    readonly binaryCode: string;
    readonly children: string;
}) {
    return (
        <p
            className="section--comment-line"
        >
            <span
                className="section--binary-code"
            >{props.binaryCode}</span>
            {props.children}
        </p>
    );
}
