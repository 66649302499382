import "./SocialNetwork.css";

export function SocialNetwork(props: {
    readonly children: JSX.Element;
    readonly link: string;
}) {
    return (
        <a
            className="social--network"
            href={props.link}
            target="_blank"
        >
            {props.children}
        </a>
    );
}
