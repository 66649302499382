import { useEffect, useState } from "react";
import { load } from "../utils";

/**
 * Wrapper that returns children only when the app is ready to be displayed
 *
 * @param props
 * @returns
 */
export function ConfiguratorWrapper(props: {
    readonly children: JSX.Element;
}): JSX.Element {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(
        (): void => {
            load(
                (): void => {
                    setIsLoaded(true);
                }
            );
        }
        , [] // only load the first time
    );

    if (isLoaded) {
        return (props.children);
    }

    // TODO: display loading screen / splash screen
    return (<p>loading</p>);
}
