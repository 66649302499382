import "./Header.css";

import { LanguageSelector } from "../components";

//@ts-ignore
// likelly invalid ts parameters
import logo from "../assets/logoTransparent.png";

const size = 50 as const;

export function Header() {
    return (
        <header
            id="header"
        >
            <LanguageSelector />

            <img
                src={logo}
                alt="Logo"
                style={{
                    height: size,
                    width: size,
                }}
            />

        </header>
    );
}
