import "./Home.css";

import { Content } from "./Content";
import { Footer } from "./Footer";
import { Header } from "./Header";

export function Home() {
    return (
        <main>
            <Header />

            <Content />

            <Footer />
        </main>
    );
}
